/** @jsx jsx */
import { jsx, Flex, Box, Styled } from 'theme-ui';
import React, { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getTranslate } from 'react-localize-redux';
import { withFormik } from 'formik';
import * as yup from 'yup';
import { Button } from '../components';
import FullHeightColumn from '../components/FullHeightColumn';
import AddressForm from './AddressForm';
import * as catalog from '../state/catalog';
import { goNext, saveAddressAndServices } from '../state/delivery';
import { showNotification } from '../state/notifications';
import { updateUser } from '../state/session';
import * as api from '../utils/api';
import { formatPrice } from '../utils/formatters';
import { postalCodeRegExp } from '../utils/regExp';
import * as streetAndApartment from '../utils/streetAndApartment';

const addressSchema = (translate, askPhone) => {
  let schema = yup.object().shape({
    street: yup.string().required(translate('form.requiredField')),
    apartment: yup.string(),
    postcode: yup
      .string()
      .required(translate('form.requiredField'))
      .matches(postalCodeRegExp, translate('form.invalidPostcode')),
    city: yup.string().required(translate('form.requiredField')),
    saveAddress: yup.bool(),
  });
  if (askPhone) {
    schema = schema.concat(
      yup.object().shape({
        phoneNumber: yup.string().matches(/^\+?[0-9\s]{6,18}$/, translate('form.invalidPhoneNumber')),
      })
    );
  }
  return schema;
};

const AddressFormik = withFormik({
  mapPropsToValues: ({ address, askPhone }) => ({
    street: address.street || '',
    apartment: address.apartment || '',
    postcode: address.postcode || '',
    city: address.city || '',
    saveAddress: address.save || false,
    phoneNumber: address.phoneNumber || '',
    submit: '',
  }),

  validationSchema: ({ translate, askPhone }) => addressSchema(translate, askPhone),

  handleSubmit: (values, { props: { onSubmit }, ...actions }) => {
    return onSubmit(values, actions);
  },

  displayName: 'AddressForm',
})(AddressForm);

const mapProfile2Address = profile => {
  const [street, apartment] = streetAndApartment.split(profile.streetAddress);
  const postcode = profile.postNumber;
  const phoneNumber = profile.phoneNumber;
  const city = profile.postOffice;
  return {
    street,
    apartment,
    postcode,
    city,
    saveAddress: !street || !postcode || !city,
    phoneNumber,
  };
};

export default ({ state }) => {
  const translate = getTranslate(useSelector(state => state.localize));
  const dispatch = useDispatch();
  const onBackClick = useCallback(() => window.history.back(), []);
  const address = useSelector(state => state.delivery.address);
  const user = useSelector(state => state.session.user);
  const { shipmentNumber, sender } = state || {};
  const askPhone = process.env.GATSBY_ACTIVE_ENV !== 'production';
  const onSubmit = useCallback(
    async (values, { setFieldError }) => {
      let response;
      try {
        response = await api.checkIfDeliveryPossible(shipmentNumber, values.postcode);
      } catch (err) {
        dispatch(showNotification('genericApiError'));
        return;
      }

      if (!response || !response.services || response.services.length === 0) {
        setFieldError('submit', translate('delivery.address.notPossible'));
        return;
      }

      await dispatch(saveAddressAndServices(values, response.services));
      if (values.saveAddress) {
        dispatch(
          updateUser({
            streetAddress: values.street.trim() + ' ' + values.apartment.trim(),
            postNumber: values.postcode.trim(),
            postOffice: values.city.trim(),
          })
        );
      }

      await dispatch(goNext());
    },
    [shipmentNumber, dispatch]
  );

  const formProps = {
    address: address || mapProfile2Address(user || {}),
    askPhone,
    onSubmit,
    translate,
  };

  if (!user) {
    return null;
  }

  return (
    <FullHeightColumn>
      <Box>
        <Button onClick={onBackClick} variant="plain" sx={{ color: 'primary' }}>
          {translate('backButton')}
        </Button>
      </Box>

      <Styled.h1 sx={{ color: 'secondary', mt: 0 }}>{translate('delivery.address.title')}</Styled.h1>
      <p sx={{ mb: 0 }}>{translate('delivery.address.description', { shipmentNumber, sender })}</p>
      <p sx={{ fontWeight: 'medium' }}>
        {translate('delivery.address.timeAndPrice', { price: formatPrice(catalog.DELIVERY_START_PRICE) })}
        <br />
        {translate('delivery.address.restriction')}
      </p>
      <p sx={{ my: 3 }}>{translate('delivery.address.formHeader')}</p>

      <Flex
        sx={{
          flex: ['auto', null, 'none'],
          flexDirection: 'column',
          justifyContent: 'flex-end',
        }}
      >
        <AddressFormik {...formProps} />
      </Flex>
    </FullHeightColumn>
  );
};
