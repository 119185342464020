/** @jsx jsx */
import { jsx, Box, Container } from 'theme-ui';
import { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { getTranslate } from 'react-localize-redux';

import { PAGE, resetDeliveryFlow } from '../state/delivery';
import SelectAddress from '../delivery/SelectAddress';
import SelectTime from '../delivery/SelectTime';
import DeliveryConfirmation from '../delivery/DeliveryConfirmation';
import DeliveryDetails from '../delivery/DeliveryDetails';
import Payment from '../delivery/Payment';
import { isBrowser } from '../utils';
import { SendParcelWidget, TrackingSearchWidget } from '../components/widgets';
import AccountLayout from '../account/AccountLayout';
import * as analytics from '../utils/analytics';

class BuyDelivery extends Component {
  constructor(props) {
    super(props);
  }

  componentWillUnmount() {
    this.props.resetDeliveryFlow();
  }

  render() {
    analytics.setPageCategory(this, 'paketit');
    let { page, translate, location: { state } = {} } = this.props;
    const { paths } = this.props.pageContext;

    const stateKeys = Object.keys(state || {});
    const hasState = stateKeys.includes('shipmentNumber') || stateKeys.includes('order');

    if (!hasState) {
      try {
        state = isBrowser && JSON.parse(window.sessionStorage.getItem('buyDelivery'));
      } catch (err) {
        // no-op
      }
    } else if (!state.saved) {
      // remember shipment if user refresh browser
      isBrowser && window.sessionStorage.setItem('buyDelivery', JSON.stringify(state));
      state.saved = true;
    }

    return (
      <AccountLayout
        title={translate('delivery.title')}
        paths={paths}
        locale={this.props.pageContext.locale || 'en'}
        checkShopDisturbance={true}
        sidebar={
          <Box>
            <SendParcelWidget sidebar sx={{ mb: 3 }} />
            <TrackingSearchWidget sidebar />
          </Box>
        }
      >
        <Container variant="parcel">
          {state && state.order ? (
            <DeliveryConfirmation state={state} />
          ) : (
            (() => {
              switch (page) {
                case PAGE.SELECT_ADDRESS:
                  return <SelectAddress state={state} />;
                case PAGE.SELECT_TIME:
                  return <SelectTime />;
                case PAGE.ADD_DETAILS:
                  return <DeliveryDetails state={state} />;
                case PAGE.PAYMENT:
                  return <Payment state={state} />;
                default:
                  return <h1>Virhetilanne</h1>;
              }
            })()
          )}
        </Container>
      </AccountLayout>
    );
  }
}

const mapStateToProps = state => ({
  translate: getTranslate(state.localize),
  page: (state.delivery || {}).page,
});

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      resetDeliveryFlow,
    },
    dispatch
  );

export default connect(mapStateToProps, mapDispatchToProps)(BuyDelivery);
