/** @jsx jsx */
import { jsx, Flex, Box, Styled } from 'theme-ui';
import React, { useCallback, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getTranslate } from 'react-localize-redux';
import { withFormik } from 'formik';
import * as yup from 'yup';
import { goBack, getDeliveryPrice, saveDiscount, saveDeliveryDetails, goNext, createOrder } from '../state/delivery';
import { Button } from '../components';
import CheckboxField from '../components/CheckboxField';
import FormField from '../components/FormField';
import FullHeightColumn from '../components/FullHeightColumn';
import { Form, Field } from 'formik';
import { getCards } from '../state/session';
import { formatPrice } from '../utils/formatters';
import AddDiscountCode from '../storage/AddDiscountCode';
import { setOrderId } from '../utils/order';
import { isBrowser } from '../utils';

const DeliveryConfirmation = ({
  values,
  errors = {},
  isSubmitting,
  handleSubmit,
  shipmentNumber,
  destinationPlaceName,
}) => {
  const translate = getTranslate(useSelector(state => state.localize));
  const service = useSelector(state => state.delivery.services.find(s => s.serviceCode === state.delivery.time));
  const addService = service && (service.additionalServices || [])[0];
  const { additionalServiceCode } = addService || {};
  const { discount } = values;
  const addDiscountVisible = !discount;
  const discountAmount =
    (discount &&
      addService &&
      (discount.amount
        ? Math.min(addService.priceVatIncluded, discount.amount)
        : +((addService.priceVatIncluded * discount.percentage) / 100).toFixed(2))) ||
    0;
  const discountedPrice = addService && addService.priceVatIncluded - discountAmount;
  const requiresPayment = !addService || +discountedPrice > 0;

  return (
    <Form
      sx={{
        width: '100%',
        my: 2,
      }}
    >
      {addDiscountVisible && <AddDiscountCode serviceCode={additionalServiceCode} />}
      {discount && (
        <div>
          {translate('discount.code')} <span sx={{ fontWeight: 'medium' }}>{discount.code}</span>{' '}
          {formatPrice(-discountAmount)}
        </div>
      )}

      <p sx={{ color: 'primary', fontSize: 4, fontWeight: 'medium' }}>
        {translate('delivery.time.price')}: {formatPrice(discountedPrice)}
      </p>

      <p>{translate('delivery.details.authorization', { shipmentNumber, pickupPoint: destinationPlaceName || '' })}</p>

      <Flex
        sx={{
          justifyContent: ['flex-end', null, 'flex-start'],
          mt: 4,
        }}
      >
        <Button type="submit" onClick={handleSubmit} disabled={isSubmitting}>
          {requiresPayment ? translate('delivery.details.continue') : translate('buyParcel.continue')}
        </Button>
      </Flex>
    </Form>
  );
};

const confirmationSchema = translate =>
  yup.object().shape({
    code: yup.string().min(3, translate('discount.tooShort')),
    discount: yup
      .object({
        code: yup.string(),
        amount: yup.string(),
      })
      .nullable(),
  });

const DeliveryConfirmationFormik = withFormik({
  mapPropsToValues: ({ discount = null }) => ({
    code: '',
    discount,
  }),

  validationSchema: ({ translate }) => confirmationSchema(translate),

  handleSubmit: (values, { props: { onSubmit }, ...actions }) => {
    return onSubmit(values, actions);
  },

  displayName: 'DiscountForm',
})(DeliveryConfirmation);

export const DeliveryDetailsForm = ({
  values,
  errors = {},
  isSubmitting,
  handleSubmit,
  shipmentNumber,
  destinationPlaceName,
}) => {
  const translate = getTranslate(useSelector(state => state.localize));
  const service = useSelector(state => state.delivery.services.find(s => s.serviceCode === state.delivery.time));
  const addService = service && (service.additionalServices || [])[0];
  const { additionalServiceCode } = addService || {};
  const { discount } = values;
  const addDiscountVisible = !discount;
  const discountAmount =
    (discount &&
      addService &&
      (discount.amount
        ? Math.min(addService.priceVatIncluded, discount.amount)
        : +((addService.priceVatIncluded * discount.percentage) / 100).toFixed(2))) ||
    0;
  const discountedPrice = addService && addService.priceVatIncluded - discountAmount;
  const requiresPayment = !addService || +discountedPrice > 0;

  return (
    <Form
      sx={{
        width: '100%',
        my: 2,
      }}
    >
      <Field
        component={CheckboxField}
        name="unattendedHandover"
        label={<span sx={{ fontWeight: 'medium' }}>{translate('delivery.details.noPresence')}</span>}
      />

      <div sx={{ mt: 4, mb: 2, fontWeight: 'medium' }}>{translate('delivery.details.doorCode')}</div>
      <div sx={{ maxWidth: '120px' }}>
        <FormField name="doorCode" />
      </div>

      <div sx={{ mt: 4, mb: 2, fontWeight: 'medium' }}>{translate('delivery.details.remarks')}</div>
      <FormField name="deliveryRemarks" />

      {addDiscountVisible && <AddDiscountCode serviceCode={additionalServiceCode} />}
      {discount && (
        <div>
          {translate('discount.code')} <span sx={{ fontWeight: 'medium' }}>{discount.code}</span>{' '}
          {formatPrice(-discountAmount)}
        </div>
      )}

      <p sx={{ color: 'primary', fontSize: 4, fontWeight: 'medium' }}>
        {translate('delivery.time.price')}: {formatPrice(discountedPrice)}
      </p>

      <p>{translate('delivery.details.authorization', { shipmentNumber, pickupPoint: destinationPlaceName || '' })}</p>

      <Flex
        sx={{
          justifyContent: ['flex-end', null, 'flex-start'],
          mt: 4,
        }}
      >
        <Button type="submit" onClick={handleSubmit} disabled={isSubmitting}>
          {requiresPayment ? translate('delivery.details.continue') : translate('buyParcel.continue')}
        </Button>
      </Flex>
    </Form>
  );
};

const detailsSchema = translate =>
  yup.object().shape({
    unattendedHandover: yup.bool(),
    doorCode: yup.string(),
    deliveryRemarks: yup.string(),
    code: yup.string().min(3, translate('discount.tooShort')),
    discount: yup
      .object({
        code: yup.string(),
        amount: yup.string(),
      })
      .nullable(),
  });

const DeliveryDetailsFormik = withFormik({
  mapPropsToValues: ({ unattendedHandover = false, doorCode = '', deliveryRemarks = '', discount = null }) => ({
    unattendedHandover,
    doorCode,
    deliveryRemarks,
    code: '',
    discount,
  }),

  validationSchema: ({ translate }) => detailsSchema(translate),

  handleSubmit: (values, { props: { onSubmit }, ...actions }) => {
    return onSubmit(values, actions);
  },

  displayName: 'DeliveryDetailsForm',
})(DeliveryDetailsForm);

export default ({ state }) => {
  const { shipmentNumber, sender, destinationPlaceName } = state || {};
  const translate = getTranslate(useSelector(state => state.localize));
  const dispatch = useDispatch();
  const onBackClick = useCallback(() => dispatch(goBack()), [dispatch]);
  const deliveryDetails = useSelector(state => state.delivery.details) || {};
  const discount = useSelector(state => state.delivery.discount) || null;
  const address = useSelector(state => state.delivery.address) || {};
  const service = useSelector(state => state.delivery.services.find(s => s.serviceCode === state.delivery.time));
  const addService = service && (service.additionalServices || [])[0];
  const servicePrice = addService && addService.priceVatIncluded;

  const onConfirm = useCallback(
    async values => {
      const { discount } = values;
      const discountAmount =
        (discount &&
          addService &&
          (discount.amount
            ? Math.min(servicePrice, discount.amount)
            : +((servicePrice * discount.percentage) / 100).toFixed(2))) ||
        0;
      const discountedPrice = servicePrice && servicePrice - discountAmount;
      const requiresPayment = !discount || +discountedPrice > 0;
      if (requiresPayment) {
        await dispatch(saveDeliveryDetails(values, true));
      } else {
        await dispatch(saveDeliveryDetails(values, false));
        await dispatch(saveDiscount(values.discount));
        const order = await dispatch(createOrder(shipmentNumber, { paymentMethod: 'FREE' }));
        if (order && order.redirectUrl) {
          setOrderId(order.orderId, order.transactionId);
          isBrowser && window.location.assign(order.redirectUrl);
        }
      }
    },
    [dispatch, servicePrice, shipmentNumber]
  );

  const onSubmit = useCallback(
    async values => {
      const { discount } = values;
      const discountAmount =
        (discount &&
          addService &&
          (discount.amount
            ? Math.min(servicePrice, discount.amount)
            : +((servicePrice * discount.percentage) / 100).toFixed(2))) ||
        0;
      const discountedPrice = servicePrice && servicePrice - discountAmount;
      const requiresPayment = !discount || +discountedPrice > 0;
      if (requiresPayment) {
        await dispatch(saveDeliveryDetails(values, true));
      } else {
        await dispatch(saveDeliveryDetails(values, false));
        const order = await dispatch(createOrder(shipmentNumber, { paymentMethod: 'FREE' }));
        if (order && order.redirectUrl) {
          setOrderId(order.orderId, order.transactionId);
          isBrowser && window.location.assign(order.redirectUrl);
        }
      }
    },
    [dispatch, servicePrice, shipmentNumber]
  );

  // preload user saved payment cards
  const user = useSelector(state => state.session.user);
  useEffect(() => {
    if (user && !user.cards) {
      dispatch(getCards());
    }
  }, [user, dispatch]);

  const formProps = { ...deliveryDetails, discount, onSubmit, translate, shipmentNumber, destinationPlaceName };
  const confirmProps = { shipmentNumber, destinationPlaceName, discount, onSubmit: onConfirm, translate };
  const code = (addService || {}).additionalServiceCode;
  const serviceDescription = code ? translate(`delivery.time.${code}`) : service.serviceDescription;
  const askDetails = service.serviceCode === '30S';

  return (
    <FullHeightColumn>
      <Box>
        <Button onClick={onBackClick} variant="plain" sx={{ color: 'primary' }}>
          {translate('backButton')}
        </Button>
      </Box>

      <Styled.h1 sx={{ color: 'secondary', mt: 0 }}>{translate('delivery.details.title')}</Styled.h1>
      <p sx={{ mb: 0 }}>
        {translate('delivery.details.details', { shipmentNumber, sender })}
        <br />
        {translate('delivery.details.address')} {address.street} {address.apartment}, {address.postcode} {address.city}
      </p>
      <p sx={{ fontWeight: 'medium', color: 'primary' }}>{serviceDescription}</p>

      <Flex
        sx={{
          flex: ['auto', null, 'none'],
          flexDirection: 'column',
          justifyContent: 'flex-end',
        }}
      >
        {askDetails ? <DeliveryDetailsFormik {...formProps} /> : <DeliveryConfirmationFormik {...confirmProps} />}
      </Flex>
    </FullHeightColumn>
  );
};
