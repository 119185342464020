/** @jsx jsx */
import { jsx, Flex, Box, Styled } from 'theme-ui';
import React, { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getTranslate } from 'react-localize-redux';
import { withFormik } from 'formik';
import * as yup from 'yup';
import { goBack, selectDeliveryTime } from '../state/delivery';
import { Button } from '../components';
import FullHeightColumn from '../components/FullHeightColumn';
import { Form, Field } from 'formik';
import RadioFieldGroup from '../components/RadioFieldGroup';
import RadioField from '../components/RadioField';
import { formatPrice } from '../utils/formatters';

const TimeForm = ({ services, values, errors = {}, isSubmitting, handleSubmit }) => {
  const translate = getTranslate(useSelector(state => state.localize));

  return (
    <Form
      sx={{
        width: '100%',
        my: 2,
      }}
    >
      <RadioFieldGroup id="deliveryTime" error={errors.deliveryTime}>
        {services.length === 0 && 'Virhe'}
        {services.map(service => {
          const addService = (service.additionalServices || [])[0] || {};
          const code = addService.additionalServiceCode;
          const description = code ? translate(`delivery.time.${code}`) : service.serviceDescription;
          const price = formatPrice(addService.priceVatIncluded);
          return (
            <Field
              key={service.serviceCode}
              sx={{ my: 2 }}
              component={RadioField}
              name="deliveryTime"
              id={service.serviceCode}
              label={
                <span sx={{ alignSelf: 'center' }}>
                  {description} {translate('delivery.time.price')}{' '}
                  <span sx={{ fontSize: 3, fontWeight: 'medium', color: 'primary' }}>{price}</span>.
                </span>
              }
            />
          );
        })}
      </RadioFieldGroup>
      <Flex
        sx={{
          justifyContent: ['flex-end', null, 'flex-start'],
          mt: 4,
        }}
      >
        <Button type="submit" onClick={handleSubmit} disabled={isSubmitting}>
          {translate('buyParcel.continue')}
        </Button>
      </Flex>
    </Form>
  );
};

const timeSchema = translate =>
  yup.object().shape({
    deliveryTime: yup.string().required(translate('form.requiredField')),
  });

const TimeFormik = withFormik({
  mapPropsToValues: ({ deliveryTime }) => ({
    deliveryTime,
  }),

  validationSchema: ({ translate }) => timeSchema(translate),

  handleSubmit: (values, { props: { onSubmit }, ...actions }) => {
    return onSubmit(values, actions);
  },

  displayName: 'TimeForm',
})(TimeForm);

export default () => {
  const translate = getTranslate(useSelector(state => state.localize));
  const dispatch = useDispatch();
  const onBackClick = useCallback(() => dispatch(goBack()), [dispatch]);
  const services = useSelector(state => state.delivery.services) || [];
  const deliveryTime = useSelector(state => state.delivery.time) || '';
  const onSubmit = useCallback(
    async values => {
      await dispatch(selectDeliveryTime(values.deliveryTime));
    },
    [dispatch]
  );
  const formProps = { services, deliveryTime, onSubmit, translate };

  return (
    <FullHeightColumn>
      <Box>
        <Button onClick={onBackClick} variant="plain" sx={{ color: 'primary' }}>
          {translate('backButton')}
        </Button>
      </Box>

      <Styled.h1 sx={{ color: 'secondary', mt: 0 }}>{translate('delivery.time.title')}</Styled.h1>

      <Flex
        sx={{
          flex: ['auto', null, 'none'],
          flexDirection: 'column',
          justifyContent: 'flex-end',
        }}
      >
        <TimeFormik {...formProps} />
      </Flex>
    </FullHeightColumn>
  );
};
