import { jsx } from 'theme-ui';
import React, { useCallback, useState } from 'react';
import { useDispatch } from 'react-redux';
import { goBack, createOrder } from '../state/delivery';
import { PaymentSelection } from '../components/Payment';
import { setOrderId } from '../utils/order';
import { isBrowser } from '../utils';

export default ({ state }) => {
  const { shipmentNumber } = state || {};
  const dispatch = useDispatch();
  const onBackClick = useCallback(() => dispatch(goBack()), [dispatch]);
  const [isProcessing, setProcessing] = useState(false);

  const onPayButton = async (paymentOptions) => {
    if (isProcessing) {
      return;
    }
    try {
      setProcessing(true);
      const order = await dispatch(createOrder(shipmentNumber, paymentOptions));
      if (order && order.redirectUrl) {
        setOrderId(order.orderId, order.transactionId);
        isBrowser && window.location.assign(order.redirectUrl);
        return;
      }
      setProcessing(false);
    } catch (error) {
      //
    }
  };

  return <PaymentSelection onPayButton={onPayButton} onBackClick={onBackClick} />;
};
