/** @jsx jsx */
import { jsx, Styled } from 'theme-ui';
import React from 'react';
import { useSelector } from 'react-redux';
import { getTranslate } from 'react-localize-redux';
import FullHeightColumn from '../components/FullHeightColumn';
import Link from '../components/Link';
import * as streetAndApartment from '../utils/streetAndApartment';

export default ({ state }) => {
  const { order } = state || {};
  const translate = getTranslate(useSelector(state => state.localize));

  const { shipmentNumber: newShipmentNumber, sender: { senderName } = {} } = order;
  const { address = {}, shipmentNumber, service } = order.delivery;
  const sender = senderName || '';
  const addressStr = `${streetAndApartment.combine(address)}, ${address.postcode} ${address.city}`;

  const addService = service && (service.additionalServices || [])[0];
  const code = (addService || {}).additionalServiceCode;
  const serviceDescription = code ? translate(`delivery.confirmation.${code}`) : service.serviceDescription;

  return (
    <FullHeightColumn>
      <Styled.h1 sx={{ color: 'secondary', mt: 0 }}>{translate('delivery.confirmation.title')}</Styled.h1>
      <p sx={{ my: 2 }}>
        {translate('delivery.confirmation.description', { shipmentNumber, sender, address: addressStr })}
      </p>
      <p sx={{ my: 2 }}>{serviceDescription}</p>
      <p sx={{ my: 2 }}>{translate('delivery.confirmation.newShipmentNumber', { newShipmentNumber })}</p>
      <p>
        <Link to="/my-pages/incoming-parcels" sx={{ textDecoration: 'underline' }}>
          {translate('delivery.confirmation.return')}
        </Link>
      </p>
    </FullHeightColumn>
  );
};
